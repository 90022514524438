import ls from "@livesession/sdk";
import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";

export type LiveSessionProps = {
  user: any;
};

const LiveSession = ({ user }: LiveSessionProps) => {
  const [isLiveSessionInitialized, setLiveSessionInitialized] = useState(false);

  // initialize live session io
  // don't need to initialize locally so don't add NEXT_PUBLIC_LIVE_SESSION_TRACK_ID to local env
  useEffect(() => {
    const trackId = process.env.NEXT_PUBLIC_LIVE_SESSION_TRACK_ID;
    if (!trackId) {
      console.log("No live session track id found");
      return;
    }
    try {
      ls.init(trackId, { keystrokes: true });
      ls.newPageView();

      // @ts-ignore
      ls.getSessionURL((url: string | undefined) => {
        Sentry.setTag("sessionURL", url ?? "No session URL found");
      });

      setLiveSessionInitialized(true);
      console.log("LiveSession initialized successfully");
    } catch (error) {
      console.error("Error initializing LiveSession:", error);
    }
  }, []);

  // add user identification information to live session
  useEffect(() => {
    if (user && user.first_name && isLiveSessionInitialized) {
      try {
        ls.identify({
          name: `${user.first_name} ${user.last_name}`,
          email: user.email || "",
        });
        console.log("User identified in LiveSession");
      } catch (error) {
        console.error("Error identifying user in LiveSession:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, isLiveSessionInitialized]);

  return null;
};

export default LiveSession;
